import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';

RHFAutoComplete.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array.isRequired,
};

export default function RHFAutoComplete({
  options = [],
  label,
  renderInput,
  getOptionLabel,
  defaultValue,
  name,
  errors,
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Autocomplete
          options={options}
          getOptionLabel={getOptionLabel}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={errors[name]}
              helperText={errors[name] && errors[name].message}
            />
          )}
          {...field}
          isOptionEqualToValue={(option, value) => value ? option.id === value.id : false}
          onChange={(_, data) => field.onChange(data)}
        />
      )}
    />
  );
}
