import { gql } from '@apollo/client';

export const LOGIN_AS_ADMIN = gql`
  mutation LoginAsAdmin($email: String!, $password: String!) {
    loginAsAdmin(email: $email, password: $password) {
      user {
        id
        role
        firstName
        lastName
        profilePic
        dob
        email
        emailVerifiedAt
        country
        countryCode
        phone
        phoneVerifiedAt
        createdAt
      }
      token
      admin {
        id
        firstName
        lastName
        dob
        profilePic
        email
        country
        countryCode
        phone
        isSuperAdmin
      }
    }
  }
`;

export const GET_ADMIN_INFO = gql`
  query AdminInfo {
    meAdmin {
      country
      countryCode
      dob
      email
      firstName
      id
      isSuperAdmin
      lastName
      phone
      profilePic
    }
  }
`;
