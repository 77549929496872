import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { MuiTelInput } from 'mui-tel-input'

// ----------------------------------------------------------------------

RHFMuiTelInput.propTypes = {
  name: PropTypes.string,
};

export default function RHFMuiTelInput({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <MuiTelInput
          {...field}
          error={!!error}
          helperText={error?.message}
          {...other}
        />
      )}
    />
  );
}
