import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { Autocomplete, TextField, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

RHFCountryStateCity.propTypes = {
  namePrefix: PropTypes.string,
};

export default function RHFCountryStateCity({ namePrefix }) {
  const [allData, setAllData] = useState(null);
  const { control, setValue, watch } = useFormContext();

  const currentValues = watch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the JSON file from the public folder
        const response = await fetch('/json/countries.json');

        // Check if the request was successful (status code 200)
        if (!response.ok) {
          throw new Error(`Failed to fetch JSON (${response.status} ${response.statusText})`);
        }

        // Parse the JSON data
        const data = await response.json();
        setAllData(data);
      } catch (error) {
        console.error('Error fetching JSON:', error);
      }
    };

    fetchData();
  }, []);
  if (!allData) return <CircularProgress />;

  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={6} sm={6}>
        <Controller
          name={`${namePrefix}.country`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              options={allData?.map((option) => option.name) || []}
              value={field.value || ''}
              renderInput={(params) => (
                <TextField {...params} label="Country" fullWidth error={!!error} helperText={error?.message} />
              )}
              onChange={(_, value) => {
                field.onChange(value);
                if (!value) {
                  setValue(`${namePrefix}.state`, null);
                  setValue(`${namePrefix}.city`, null);
                }
              }}
            />
          )}
        />
      </Grid>
      {currentValues?.location?.country && (
        <Grid item xs={6} sm={6}>
          <Controller
            name={`${namePrefix}.state`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
              value={field.value || ''}
                options={
                  allData
                    .filter((option) => option.name === currentValues.location.country)[0]
                    ?.states.map((option) => option.name) || []
                }
                renderInput={(params) => (
                  <TextField {...params} label="State" fullWidth error={!!error} helperText={error?.message} />
                )}
                onChange={(_, value) => {
                  field.onChange(value);
                }}
              />
            )}
          />
        </Grid>
      )}

      {currentValues?.location?.state ? (
        <Grid item xs={6} sm={6}>
          <Controller
            name={`${namePrefix}.city`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
              value={field.value || ''}
                options={
                  allData
                    .filter((option) => option.name === currentValues.location.country)[0]
                    ?.states.filter((option) => option.name === currentValues.location.state)[0]
                    ?.cities.map((option) => option.name) || []
                }
                renderInput={(params) => (
                  <TextField {...params} label="City" fullWidth error={!!error} helperText={error?.message} />
                )}
                onChange={(_, value) => {
                  field.onChange(value);
                }}
              />
            )}
          />
        </Grid>
      ) : null}
    </Grid>
  );
}
